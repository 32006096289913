import responsibilityFormStore from '@/modules/responsibilities/store/responsibility-form-store';
import responsibilityListStore from '@/modules/responsibilities/store/responsibility-list-store';
import responsibilityLogsStore from '@/modules/responsibilities/store/responsibility-logs-store';

export default {
    namespaced: true,

    modules: {
        form: responsibilityFormStore,
        list: responsibilityListStore,
        logs: responsibilityLogsStore,
    },
};
