import { ResposabilityService} from '@/modules/responsibilities/responsibility-service';
import Errors from '@/shared/error/errors';

// action types
export const DO_FETCH = 'doFetch';

// mutation types
export const FETCH_SUCCESS = 'fetchSuccess';
export const SET_CURRENT_PAGE = 'setCurrentPage';
export const SET_FILTERS = 'setFilters';

const state = {
    rows: [],
    total: 0,
    storeFilters: {},
    currentPage: 1,
};

const getters = {
    rows: (state) => state.rows || [],
    total: (state) => state.total,
    filters: (state) => state.storeFilters,
    currentPage: (state) => state.currentPage,
};

const actions = {
    [DO_FETCH](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'responsibility/getResponsibilities', { root: true });
            const filters = { params: { ...payload, pagination: true } };
            ResposabilityService.listResponsibilities(filters)
                .then((data) => {
                    context.commit(FETCH_SUCCESS, {
                        rows: data.data,
                        total: data.pagination.total,
                    });
                })
                .catch(( error ) => {
                    Errors.handler(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/getResponsibilities', { root: true });
                });
        });
    }
};

const mutations = {
    [FETCH_SUCCESS](state, payload) {
        state.rows = payload.rows;
        state.total = payload.total;
    },
    [SET_CURRENT_PAGE](state, page) {
        state.currentPage = page;
    },
    [SET_FILTERS](state, { key, value }) {
        state.storeFilters[key] = value;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
