import { ResposabilityService} from '@/modules/responsibilities/responsibility-service';
import ApiService from '@/shared/services/api.service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_FETCH_PERMISSIONS = 'doFetchPermissions';
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_SELECT = 'select';
export const DO_UNSELECT = 'unselect';
export const DO_REMOVE = 'remove';
export const ADD_PERMISSIONS_TO_RESPONSABILITY = 'addPermissionsToResponsability';
export const GET_RESPONSABILITY_BY_ID = 'getResposabilityById';
export const GET_PERMISSIONS_BY_RESPONSIBILITY = 'getPermissionsByResponsibility';
export const DELETE_PERMISSIONS_TO_RESPONSABILITY = 'deletePermissionsFromResponsibility';

// mutation types
export const SET_PERMISSIONS = 'setPermissions';
export const SET_SELECTED = 'setSelected';
export const REMOVE_SELECTED = 'removeSelected';
export const UPDATE_SUCCESS = 'updateSuccess';
export const SET_PERMISSIONS_BY_RESPONSIBILITY = 'setPermissionsByResposability';

const state = {
    permissions: [],
    record: null,
    openEditing: false,
    permissionsByResposibility: []
};

const getters = {
    permissions: (state) => state.permissions,
    permissionsByResposibility: (state) => state.permissionsByResposibility,
    record: (state) => state.record,
    openEditing: (state) => state.openEditing,
};

const actions = {
    [DO_FETCH_PERMISSIONS](context, payload) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'responsibility/getPermissions', { root: true });
            ResposabilityService.listPermissions()
                .then((data) => {
                    context.commit(SET_PERMISSIONS, data.data);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/getPermissions', { root: true });
                });
        });
    },
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'responsibility/create', { root: true });
            ResposabilityService.create(values)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    context.commit(UPDATE_SUCCESS);
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, payload }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'responsibility/update', { root: true });
            ResposabilityService.update(id, payload)
                .then(() => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    context.commit(UPDATE_SUCCESS);
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/update', { root: true });
                });
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'responsibility/remove', { root: true });
            ResposabilityService.remove(id)
                .then(() => {
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    context.commit('responsability/list/removeRecord', id, { root: true });
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/remove', { root: true });
                });
        });
    },
    [ADD_PERMISSIONS_TO_RESPONSABILITY](context, payload) {
        context.commit('shared/activateLoading', 'responsibility/addPermissionsToResponsibility', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('association-requests/multiple', payload)
                .then(() => {
                    resolve()
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error)
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/addPermissionsToResponsibility', { root: true });
                })
            });
    },
    [DELETE_PERMISSIONS_TO_RESPONSABILITY]( context, payload) {
        context.commit('shared/activateLoading', 'responsibility/deletePermissionsFromResponsibility', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.post('association-requests', payload)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((error) => {
                Errors.handle(error);
                reject(error);
            })
            .finally(() => {
                context.commit('shared/deactivateLoading', 'responsibility/deletePermissionsFromResponsibility', { root: true });
            })
        });
    },
    [GET_RESPONSABILITY_BY_ID](context, id) {
        context.commit('shared/activateLoading', 'responsibility/getResposibilityById', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`responsibilities/${id}`)
                .then((data) => {
                    context.commit(SET_PERMISSIONS_BY_RESPONSIBILITY, data.data);
                    resolve()
                })
                .catch((error) => {
                    Errors.handle(error);
                    reject(error)
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'responsibility/getResposibilityById', { root: true });
                })
            });
    },
    [DO_SELECT](context, record) {
        context.commit(SET_SELECTED, record);
    },
    [DO_UNSELECT](context) {
        context.commit(REMOVE_SELECTED);
    }
};

const mutations = {
    [SET_PERMISSIONS](state, permissions) {
        state.permissions = permissions;
    },
    [SET_SELECTED](state, record) {
        state.record = record;
        state.openEditing = true;
    },
    [REMOVE_SELECTED](state) {
        state.record = {};
        state.openEditing = false;
    },
    [UPDATE_SUCCESS](state) {
        state.openEditing = false;
    },
    [SET_PERMISSIONS_BY_RESPONSIBILITY](state, permissions) {
        state.permissionsByResposibility = permissions.permissions;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
