import ApiService from '@/shared/services/api.service';

export class ResposabilityService {
    static listPermissions() {
        return new Promise((resolve, reject) => {
            ApiService.get('permissions')
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static listResponsibilities(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/responsibilities', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
    static listLogs(params) {
        return new Promise((resolve, reject) => {
            ApiService.query('/logs', params)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static create(data) {
        return new Promise((resolve, reject) => {
            ApiService.post( '/responsibilities?echo=true', data)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static update(id, data) {
        return new Promise((resolve, reject) => {
            ApiService.patch(`/responsibilities/${id}`, { ...data })
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    static async remove(id) {
        return new Promise((resolve, reject) => {
            ApiService.delete(`/responsibilities/${id}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    static async addPermissionsToResponsability(data) {
        return new Promise((resolve, reject) => {
            ApiService.post('association-requests/multiple', data)
                .then(() => resolve())
                .catch((error) => reject(error));
            });
    }

}
